import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';

class StopModal extends Component {
    render() {
        return (
            <Modal className="cancel-run-modal" open={this.props.openModal} onClose={() => this.props.handleClose()}>
                <div className="modal">
                    <div className="close" onClick={() => this.props.handleClose()}>
                        <span className="highlight fas fa-times"/>
                    </div>

                    <div className="modal-card">
                        {this.props.deletedTeam ?
                            <div className="successfully-deleted">Team successfully deleted!</div>
                            :
                            <div className="modal-card-content delete-project-modal">
                                <h2>Are you sure you want to stop this run?</h2>
                                <p>Once it is stopped, you will need to reupload and start the run again.</p>
                                <div className="buttons">
                                    <button className="btn confirm" onClick={() => this.props.stopRun()}>Yes</button>
                                    <button className="btn cancel" onClick={() => this.props.handleClose()}>No, Continue Running the Model</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default StopModal;
