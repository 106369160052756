import React from 'react';
import Main from './components/Main.js';
import './css/App.css';

function App() {

    return (
        <div className="App">
            <Main/>
        </div>
    );
}

export default App;
