import React, {Component} from 'react';
import Running from './running/Running.js';
import Upload from './upload/Upload.js';

import utils from '../utils';
import config from '../config';
import '../css/App.css';


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder: "",

            gpu: 4,
            cpu: 24,
            ram: 100,

            version: 3,

            pythonFile: [],
            pythonUrl: "",
            requirementsFile: [],
            requirementsUrl: "",
            dataFiles: [],
            dataUrls: [],

            priceHourly: 0,
            priceDaily: 0,

            submitting: false,
            running: false,

            projectName: "",
            ipAddress: "",

            error: "",

            deleteInstance: false,
        };

        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.handleSettingsChange = this.handleSettingsChange.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
        this.submit = this.submit.bind(this);
        this.stopRun = this.stopRun.bind(this);
    }

    componentDidMount() {
        this.calculatePrice();

        let folder = Math.floor(Math.random() * 100001);
        this.setState({ folder: folder });
    }

    handleVersionChange(versionNumber) {
        this.setState({ version: versionNumber });
    }

    handleUploadChange(type, values, url) {
        if (type === "python") {
            this.setState({pythonFile: values, pythonUrl: url, error: "" });
        } else if (type === "requirements") {
            this.setState({requirementsFile: values, requirementsUrl: url});
        } else if (type === "data") {
            this.setState({dataFiles: values, dataUrls: url});
        }
    }

    handleSettingsChange(type, value) {
        if (type === "gpu") {
            this.setState({ gpu: value }, () => {
                this.calculatePrice();
            });
        } else if (type === "cpu") {
            this.setState({ cpu: value }, () => {
                this.calculatePrice();
            });
        } else if (type === "ram") {
            this.setState({ ram: value }, () => {
                this.calculatePrice();
            });
        }
    }

    handleDelete(type) {
        if (type === "python") {
            this.setState({
                pythonFile: [],
                pythonUrl: ""
            });
        } else if (type === "requirements") {
            this.setState({
                requirementsFile: [],
                requirementsUrl: ""
            });
        } else if (type === "data") {
            this.setState({
                dataFiles: [],
                dataUrls: []
            });
        }
    }

    clearFiles() {
        this.setState({pythonFile: [], pythonUrl: "", requirementsFile: [], requirementsUrl: "", dataFiles: [], dataUrls: "" });
    }

    calculatePrice() {
        let totalGpu = this.state.gpu * 1.46;
        let totalCpu = this.state.cpu * 0.033174;
        let totalRam = this.state.ram * 0.004446;

        let priceHourly = (Math.round(110*(totalGpu + totalCpu + totalRam))/100).toFixed(2);
        let priceDaily = (Math.round(110* 24 *(totalGpu + totalCpu + totalRam))/100).toFixed(2);

        this.setState({ priceHourly: priceHourly, priceDaily: priceDaily });
    }

    submit() {
        let { version, pythonUrl, requirementsUrl, dataUrls, cpu, gpu, ram } = this.state;

        if (pythonUrl) {
            this.setState({
                submitting: true,
                deleteInstance: false,
            });

            let requirements = requirementsUrl.length > 0 ? requirementsUrl : "https://stagingjouncerfrontend.s3.us-east-2.amazonaws.com/static/ml/requirements.txt";

            const url = `${config.BACKEND_HOST}/spinup`;
            const params = {
                gpu: gpu,
                cpu: cpu,
                ram: ram,

                python_version: version,

                python_url: pythonUrl,
                // python_url: "https://stagingjouncerfrontend.s3.us-east-2.amazonaws.com/static/ml/test_print_file.py",
                requirements_url: requirements,
                // requirements_url: "https://stagingjouncerfrontend.s3.us-east-2.amazonaws.com/static/ml/requirements.txt",
                data_urls: dataUrls,
            }

            utils.get(url, params)
                .then(res => {
                    if (res) {
                        this.setState({
                            projectName: res.project_name,
                            ipAddress: res.ip_address,
                            running: true
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({ error: "Please add a Python executable file" });
        }
    }

    stopRun() {
        this.setState({
            running: false,
            submitting: false,
            deleteInstance: true,
        });

        // Delete instance
        const url = `${config.BACKEND_HOST}/delete_instance`;
        const params = {
            project_name: this.state.projectName,
        }

        utils.get(url, params)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        let {version, pythonFile, requirementsFile, dataFiles, dataUrls, folder, cpu, gpu, ram, priceHourly, priceDaily, projectName, ipAddress, submitting, error, deleteInstance} = this.state;

        if (this.state.running) {
            return(
                <Running
                    // SUBMISSION RESPONSE
                    projectName={projectName}
                    ipAddress={ipAddress}

                    // FILES
                    pythonFile={pythonFile}
                    requirementsFile={requirementsFile}
                    dataFiles={dataFiles}

                    // STOP
                    stopRun={this.stopRun}
                    deleteInstance={deleteInstance}/>
            );
        } else {
            return (
                <Upload
                    // VERSION
                    version={version}
                    handleVersionChange={this.handleVersionChange}

                    // FILES
                    pythonFile={pythonFile}
                    requirementsFile={requirementsFile}
                    dataFiles={dataFiles}
                    dataUrls={dataUrls}
                    handleUploadChange={this.handleUploadChange}
                    handleDelete={this.handleDelete}
                    clearFiles={this.clearFiles}
                    folder={folder}

                    // SETTINGS
                    cpu={cpu}
                    gpu={gpu}
                    ram={ram}
                    handleSettingsChange={this.handleSettingsChange}

                    // PRICE
                    priceHourly={priceHourly}
                    priceDaily={priceDaily}

                    // SUBMIT
                    submit={this.submit}
                    submitting={submitting}

                    // SUBMISSION ERROR
                    error={error}
                />
            );
        }
    }
}


export default Main;
