import React, {Component} from 'react';
import Files from './Files.js';
import Settings from './Settings.js';

import '../../css/App.css';

class Version extends Component {
    render() {
        return (
            <div className="version upload-section">
                Python Version
                <div className="version-numbers">
                    <div className={`number ${this.props.version === 2 ? "active" : ""}`} onClick={() => this.props.handleVersionChange(2)}> 2.7 </div>
                    <div className={`number ${this.props.version === 3 ? "active" : ""}`} onClick={() => this.props.handleVersionChange(3)}> 3.6 </div>
                </div>
            </div>
        );
    }
}

class Price extends Component {

    render() {
        return (
            <div className="price upload-section">
                <div className="final-price">
                    <p>Final Cost</p>
                    <div className="prices">
                        <div>${this.props.priceHourly}<p className="price">/hr</p></div>
                        <div>${this.props.priceDaily}<p className="price">/day</p></div>
                    </div>
                </div>
                <div className="price-note">You will only be charged for the time that it takes to run your model. We will automatically stop charging you once the task is finished.</div>
            </div>
        )
    }
}

class ErrorMessage extends Component {
    render() {
        return (
            <div className="error-banner">
                {this.props.error}
            </div>
        )
    }
}

class Button extends Component {

    render() {
        return (
            <div className="submit-btn" onClick={() => this.props.submit()}>
                {this.props.submitting ?
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                    :
                    "Run Models"}
            </div>
        )
    }
}

class Upload extends Component {
    render() {
        let {version, pythonFile, requirementsFile, dataFiles, dataUrls, folder, cpu, gpu, ram, priceHourly, priceDaily, error, submitting} = this.props;
        return (
            <div className="upload-container">
                <Version
                    version={version}
                    handleVersionChange={this.props.handleVersionChange}/>
                <Files
                    pythonFile={pythonFile}
                    requirementsFile={requirementsFile}
                    dataFiles={dataFiles}
                    dataUrls={dataUrls}
                    handleUploadChange={this.props.handleUploadChange}
                    handleDelete={this.props.handleDelete}
                    clearFiles={this.props.clearFiles}
                    folder={folder}/>
                <Settings
                    cpu={cpu}
                    gpu={gpu}
                    ram={ram}
                    handleSettingsChange={this.props.handleSettingsChange}/>
                <Price
                    priceHourly={priceHourly}
                    priceDaily={priceDaily}/>
                {error ?
                    <ErrorMessage error={error}/> : null
                }
                <Button
                    submit={this.props.submit}
                    submitting={submitting}/>
            </div>
        );
    }
}

export default Upload;
