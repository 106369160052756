import React, {Component} from 'react';

import Octicon, {X, CloudUpload} from '@primer/octicons-react';
import S3FileUpload from 'react-s3';

import Dropzone from 'react-dropzone';

// const uniqueString = require('unique-string');

class DropArea extends Component {

    render() {
        let {file, type, onDrop, accept, activeText, rejectText, defaultText, sideNote} = this.props;

        return (
            <Dropzone onDrop={onDrop} accept={accept}>
                {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => {
                    if (file && file.length > 0 && type !== "data") {
                        return(
                            <section>
                                <div className="add-files-box upload-files-added">
                                    <div onClick={() => this.props.handleDelete(type)}>
                                        <Octicon icon={X} size="medium" className="x-icon"/>
                                        <div className="x-circle"/>
                                    </div>
                                    <p className="added-file-text">{file[0].name}</p>
                                    {type === "python" ?
                                        <div className="file-type-name">PYTHON</div>
                                        :
                                        <div className="file-type-name">REQUIREMENTS</div>
                                    }
                                </div>
                            </section>
                        );
                    } else if (isDragReject) {
                        return(
                            <section>
                                <div className="add-files-box upload-files-reject" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="reject-text">{rejectText}</p>
                                </div>
                            </section>
                        );
                    } else if (isDragActive) {
                        return(
                            <section>
                                <div className="add-files-box upload-files-active" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Octicon icon={CloudUpload} size="medium" className="icon"/>
                                    <p>{activeText}</p>
                                </div>
                            </section>
                        );
                    }
                    return(
                        <section>
                            <div className="add-files-box" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{defaultText}</p>
                            {sideNote ? <p className="data-files-sidenote">Upload large file sets as a zipped folder</p> : null}
                            </div>
                        </section>
                    );
                }}
            </Dropzone>
        );
    }
}

class Files extends Component {

    constructor(props) {
        super(props);

        this.state = {
            waitingForUpload: false,
        }

        this.onDropPython = this.onDropPython.bind(this);
        this.onDropRequirements = this.onDropRequirements.bind(this);
        this.onDropDataFiles = this.onDropDataFiles.bind(this);
    }

    onDropPython(accepted, rejected) {

        if (accepted.length > 0) {
            const config = {
                bucketName: 'stagingjouncerfrontend',
                dirName:`static/upload_ml/${this.props.folder}`,
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }

            S3FileUpload
                .uploadFile(accepted[0], config)
                .then(data => {
                    // console.log("s3 file uploaded properly", data);

                    let pythonFile = [accepted[0]];
                    let url = encodeURI(data.location);
                    this.props.handleUploadChange("python", pythonFile, url);
                })
                .catch(err => console.error(err));
        }
    }

    onDropRequirements(accepted, rejected) {

        if (accepted.length > 0) {
            const config = {
                bucketName: 'stagingjouncerfrontend',
                dirName:`static/upload_ml/${this.props.folder}`,
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }

            S3FileUpload
                .uploadFile(accepted[0], config)
                .then(data => {
                    // console.log("s3 file uploaded properly", data);

                    let requirementsFile = [accepted[0]];
                    let url = encodeURI(data.location);
                    this.props.handleUploadChange("requirements", requirementsFile, url);
                })
                .catch(err => console.error(err));
        }
    }

    onDropDataFiles(accepted, rejected) {
        let dataFiles = this.props.dataFiles;
        let urls = this.props.dataUrls;

        if (accepted.length > 0) {

            this.setState({ waitingForUpload: true });

            const config = {
                bucketName: 'stagingjouncerfrontend',
                dirName:`static/upload_ml/${this.props.folder}`,
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }


            accepted.forEach(file => {
                file.id = file.name + Math.floor(Math.random() * 10001);
                dataFiles.push(file);

                S3FileUpload
                    .uploadFile(file, config)
                    .then(data => {
                        // console.log("s3 file uploaded properly", data);
                        urls.push(encodeURI(data.location));

                        this.props.handleUploadChange("data", dataFiles, urls);

                        this.setState({ waitingForUpload: false });
                    })
                    .catch(err => console.error(err));
            });
        }
    }

    render() {
        return (
            <div className="files upload-section">
                Drag a file or browse for a file to upload
                <div className="row">
                    <DropArea
                        file={this.props.pythonFile}
                        type="python"
                        onDrop={this.onDropPython}
                        handleDelete={this.props.handleDelete}
                        // accept=".py"
                        activeText="Upload Python File"
                        rejectText="This needs to be a Python File"
                        defaultText="Python File"/>

                    <DropArea
                        file={this.props.requirementsFile}
                        type="requirements"
                        onDrop={this.onDropRequirements}
                        handleDelete={this.props.handleDelete}
                        // accept="text/plain, requirements.txt"
                        activeText="Upload Requirements"
                        rejectText="This needs to be a .txt file"
                        defaultText="Requirements File"/>
                </div>

                <div className="data-row">
                    {this.state.waitingForUpload ?
                        <section className="data-added">
                            <div className="upload-files-data uploading-file">
                                <Octicon className="uploading-icon" icon={CloudUpload}/>
                                <p className="added-file-text">Uploading</p>
                                <div className="file-type-name">DATA</div>
                            </div>
                        </section>
                    : null}

                    {this.props.dataFiles.map(
                        file => {
                            return (
                                <section className="data-added" key={file.id}>
                                    <div className="upload-files-data">
                                        <div onClick={() => this.props.handleDelete("data")}>
                                            <Octicon icon={X} size="medium" className="x-icon"/>
                                            <div className="x-circle"/>
                                        </div>
                                        <p className="added-file-text">{file.name}</p>
                                        <div className="file-type-name">DATA</div>
                                    </div>
                                </section>
                        )}
                    )}

                    <DropArea
                        type="data"
                        onDrop={this.onDropDataFiles}
                        // accept="text/plain, image/jpeg, image/png, image/gif, application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                        activeText="Upload Data Files"
                        rejectText="File type not supported"
                        defaultText="Data Files"
                        sideNote={true}/>
                </div>

                {this.props.dataFiles.length > 0 || this.props.requirementsFile.length > 0 || this.props.pythonFile.length > 0 ?
                    <div className="clear" onClick={() => this.props.clearFiles()}>Clear All Files</div>
                    : null
                }

            </div>
        );
    }
}

export default Files;
