import React, {Component} from 'react';
import Octicon, {CloudDownload} from '@primer/octicons-react';

class OutputFiles extends Component {

    render() {
        let {outputFiles} = this.props;

        return (
            <div className="files upload-section">
                <p className="output-files-title">Generated Files</p>
                <div className="data-row">
                    {outputFiles ?
                        <section className="data-added">
                            <a className="upload-files-data" href={outputFiles}>
                                <Octicon icon={CloudDownload}/>
                                <p className="added-file-text">Zip File</p>
                                <div className="file-type-name">OUTPUT</div>
                            </a>
                        </section>
                        :
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

export default OutputFiles;
