import config from './config';
const rp = require('request-promise-native');
// var FormData = require('form-data');
// const fetch = require('node-fetch');
// const _ = require("lodash");


const get = async (url, params = undefined) => {
    if (url[0] === "/") {
        url = `${config.BACKEND_HOST}${url}`;
    }
    let options = {
        uri: url,
        qs: params,
        json: true
    };
    return rp(options);
}


// const post = (url, params = undefined) => {
//     var form = new FormData();
//     if (url[0] === "/") {
//         url = `${config.BACKEND_HOST}${url}`;
//     }
//     for (var key in params) {
//         if (key in params) {
//             let val = params[key];
//             console.log(key, val, typeof val);
//             if (_.isArray(val)){
//                 val.forEach((elem, i) => {
//                     form.append(key + `[${i}]`, elem);
//                 });
//             }
//             else{
//                 form.append(key, val);
//             }
//         }
//     }
//     const options = {
//         method: "GET",
//         headers: {
//             "Content-Type": "multipart/form-data",
//             'Access-Control-Allow-Credentials': true,
//             // 'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
//             // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
//             'Access-Control-Allow-Origin': 'http://35.196.173.240'
//         },
//         body: form,
//         credentials: "include",
//         mode: "cors"
//     };
//     return fetch(url, options);
// }


export default {
    get,
    // post
};
