import React, {Component} from 'react';

class ProgressBar extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
        }

        this.startProgress = this.startProgress.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.startProgress();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    startProgress() {
        let current_progress = -0.02;
        let step = 0.003; // the smaller this is the slower the progress bar

        let interval = setInterval(function() {
            current_progress += step;
            let progress = ((Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 10) / 10) + 1).toFixed(1);

            if (!this._isMounted || progress >= 100){
                clearInterval(interval);
            } else if (progress >= 99) {
                step = 0.3;
            } else if (progress >= 80) {
                step = 0.08;
            } else if (progress >= 60) {
                step = 0.01;
            } else if (progress >= 10) {
                step = 0.005;
            } else {
                step = 0.003;
            }

            // this._isMounted checks and stops setInterval when the instance is deleted.
            // this makes sure that state is not updated on an unmounted component.
            if (this._isMounted) {
                this.setState({ progress: progress });
            }

        }.bind(this), 100);
    }

    render() {
        let progressStyle = {
            width: `${this.state.progress}%`,
        }

        return (
            <div className="progress">
                <div className="status">Setting Up Project</div>
                <div className="progress-bg">
                    <div className="progress-bar progress-bar-striped"
                        role="progressbar"
                        style={progressStyle}
                        aria-valuenow={this.state.progress}
                        aria-valuemin="0"
                        aria-valuemax="100">{this.state.progress}%</div>
                </div>
            </div>
        );
    }
}

export default ProgressBar;
