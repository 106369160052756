import React, {Component} from 'react';

import utils from '../../utils';
import config from '../../config';


class Terminal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          terminalText: ['Waiting to connect...']
        };

        this.checkSocketReady = this.checkSocketReady.bind(this);
        this.openSocket = this.openSocket.bind(this);
    }

    componentDidMount() {
        // checking if socket is ready after waiting for 1 min
        setTimeout(() => this.checkSocketReady(), 60000);
    }

    checkSocketReady() {
        // Backend will send 1 or 0 to show if the socket is ready.
        // If 1, socket is ready and you can connect.
        // If 0, continue to ping the backend every 10 seconds.

        // let projectName = this.props.projectName;

        let socketConn = setInterval(function() {
                if (this.props.deleteInstance) {
                    clearInterval(socketConn);
                }

                console.log("checking if socket is ready");

                const url = `${config.BACKEND_HOST}/socket_ready`;
                const params = {
                    project_name: this.props.projectName
                }

                utils.get(url, params)
                    .then(res => {
                        console.log(res);
                        if (res === 1) {
                            // if socket is ready, openSocket and stop pinging the backend
                            console.log("socket is ready");
                            clearInterval(socketConn);
                            this.openSocket();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });

            }.bind(this), 10000);
    }

    openSocket() {
        console.log("opening socket");
        let socket = new WebSocket(`ws://${this.props.ipAddress}`);

        // send to open the socket
        socket.onopen = function(e) {
            console.log("sending data...");
            socket.send('hello Houston I am ready to receive');
            console.log("done.");

            // start calling storage_ready, param: project_name.
            // 0 if not ready, 1 if ready, get back a dictionary with url, url will lead to zip file
            this.fetchFiles();
        }.bind(this);

        // whenever data is received through the socket, add it to the terminal text array
        socket.onmessage = function(event) {
            let newTerminalText = this.state.terminalText;
            let newText = event.data.toString();

            newTerminalText.push(newText);

            this.setState({ terminalText: newTerminalText });
            this.updateScroll();

        }.bind(this);

        socket.onerror = function(event) {
            console.log(event);
        };
    }

    // when terminal has finished printing, try to fetch output files
    fetchFiles() {

        let filesConn = setInterval(function() {
            console.log("fetching files");

            const url = `${config.BACKEND_HOST}/storage_ready`;
            const params = {
                project_name: this.props.projectName
            }

            utils.get(url, params)
                .then(res => {
                    console.log(res);
                    if (res !== 0) {
                        // if socket is ready, openSocket and stop pinging the backend
                        clearInterval(filesConn);
                        this.props.showOutput(res);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }.bind(this), 3000);
    }

    updateScroll(){
        var element = document.getElementById("terminal-window");
        element.scrollTop = element.scrollHeight;
    }

    render() {
        return (
            <div className="terminal">
                <div id="terminal-window" className="terminal-window">
                    {this.state.terminalText.map((text, index) =>
                        <div className="terminal-text" key={index}>{text}</div>
                    )}
                </div>
            </div>
        )
    }
}

export default Terminal;
