import React, {Component} from 'react';
import Slider from '@material-ui/core/Slider';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gpu: 4,
            cpu: 24,
            ram: 100,

            cpuMax: 32,
            ramMin: 22,
            ramMax: 156,
            ramStep: 1,
        };

        this.handleGpuChange = this.handleGpuChange.bind(this);
        this.handleCpuChange = this.handleCpuChange.bind(this);
        this.handleRamChange = this.handleRamChange.bind(this);
    }

    gpuMarks() {
        let gpuMarks = [];

        for (var i = 0; i < 4; i++) {
            gpuMarks.push({
                value: Math.pow(2, i),
                label: Math.pow(2, i)
            });
        }

        return gpuMarks;
    }

    handleGpuChange(event, newValue) {
        let cpuMaxVal;

        if (newValue === 1) {
            cpuMaxVal = 8;
        } else if (newValue === 2) {
            cpuMaxVal = 16;
        } else if (newValue === 4) {
            cpuMaxVal = 32;
        } else if (newValue === 8) {
            cpuMaxVal = 64;
        }

        if (this.props.cpu > cpuMaxVal) {
            this.props.handleSettingsChange("cpu", cpuMaxVal);

            let ramMin = Math.ceil(cpuMaxVal * 0.9);
            let ramMax = cpuMaxVal * 6.5;

            this.setState({ ramMin: ramMin, ramMax: ramMax });

            if (this.props.ram > ramMax) {
                this.setState({ ram: ramMax});
            } else if (this.props.ram < ramMin) {
                this.setState({ ram: ramMin});
            }
        }

        this.setState({ cpuMax: cpuMaxVal });
        this.props.handleSettingsChange("gpu", newValue);

    };

    cpuMarks() {
        let cpuMarks = [{
            value: 1,
            label: '1'
        }];

        let cpuMaxTen, cpuMax, num;

        if (this.props.gpu === 1) {
            num = 2;
            cpuMaxTen = 4;
            cpuMax = 8;
        } else if (this.props.gpu === 2) {
            num = 2;
            cpuMaxTen = 8;
            cpuMax = 16;
        } else if (this.props.gpu === 4) {
            num = 10;
            cpuMaxTen = 4;
            cpuMax = 32;
        } else if (this.props.gpu === 8) {
            num = 10;
            cpuMaxTen = 7;
            cpuMax = 64;
        }

        for (var i = 1; i < cpuMaxTen; i++) {
            cpuMarks.push({
                value: i * num,
                label: i * num
            });
        }

        cpuMarks.push({
            value: cpuMax,
            label: cpuMax,
        });

        return cpuMarks;
    }

    handleCpuChange(event, newValue) {

        let ramMin = Math.ceil(newValue * 0.9);
        let ramMax = newValue * 6.5;

        this.setState({ ramMin: ramMin, ramMax: ramMax});
        this.props.handleSettingsChange("cpu", newValue);

        if (this.props.ram > ramMax) {
            this.props.handleSettingsChange("ram", ramMax);
        } else if (this.props.ram < ramMin) {
            this.props.handleSettingsChange("ram", ramMin);
        }
    }

    ramMarks() {
        let {ramMin, ramMax} = this.state;

        let ramMarks = [{
            value: ramMin,
            label: ramMin
        }];

        // calculating the steps for the ticks
        let multiple = 10;
        if (ramMax > 250) {
            multiple = 50;
        } else if (ramMax > 100) {
            multiple = 20;
        } else if (ramMax < 40) {
            multiple = 5;
        }

        // finding the min and max for the ticks in between to iterate over
        let min = Math.ceil(ramMin / multiple);
        let max = Math.ceil(ramMax / multiple);

        // prevent numbers from overlapping because they are too close to the min
        if ((min === 1 || (min * multiple) - ramMin < 10) && ramMin > 15) {
            min = min + 1;
        }

        // prevent numbers from overlapping because they are too close to the max
        if ((ramMax - ((max - 1) * multiple) < 10) && ramMax > 80) {
            max = max - 1;
        }

        for (var i = min; i < max; i++) {
            ramMarks.push({
                value: i * multiple,
                label: i * multiple
            });
        }

        ramMarks.push({
            value: ramMax,
            label: ramMax,
        });

        return ramMarks;
    }

    handleRamChange(event, newValue) {
        let ramSteps;

        if (newValue >= 500) {
            ramSteps = 10;
        } else if (newValue >= 100) {
            ramSteps = 5;
        } else if (newValue >= 50) {
            ramSteps = 2;
        } else if (newValue >= 20) {
            ramSteps = 1;
        } else if (newValue >= 10) {
            ramSteps = 0.5;
        } else {
            ramSteps = 0.25;
        }

        this.setState({ ramStep: ramSteps });
        this.props.handleSettingsChange("ram", newValue);
    }

    render() {

        function valuetext(value) {
            return `${value}`;
        }

        let { cpuMax, ramMin, ramMax, ramStep } = this.state;
        let { gpu, cpu, ram } = this.props;

        return (
            <div className="settings upload-section">
                <div id="gpu-slider" className="GPU slider">
                    <p>GPUs</p>
                    {/*<input type="range" id="myRange" step="20"/>*/}
                    <Slider
                        defaultValue={gpu}
                        value={gpu}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        min={1}
                        max={8}
                        onChange={this.handleGpuChange}
                        valueLabelDisplay="auto"
                        marks={this.gpuMarks()}
                      />
                </div>
                <div className="CPU slider">
                    <div className="setting-title">CPU
                        <p className="units">Cores</p>
                    </div>
                    <Slider
                        defaultValue={cpu}
                        value={cpu}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={2}
                        min={1}
                        max={cpuMax}
                        valueLabelDisplay="auto"
                        marks={this.cpuMarks()}
                        onChange={this.handleCpuChange}
                      />
                </div>
                <div className="RAM slider">
                    <div className="setting-title">RAM
                        <p className="units">GB</p>
                    </div>
                    <Slider
                        defaultValue={ram}
                        value={ram}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={ramStep}
                        min={ramMin}
                        max={ramMax}
                        valueLabelDisplay="auto"
                        marks={this.ramMarks()}
                        onChange={this.handleRamChange}
                      />
                </div>
            </div>
        );
    }
}

export default Settings;
