import React, {Component} from 'react';
// import RunningFilesList from './RunningFilesList.js';
import OutputFiles from './OutputFiles.js';
import StopModal from './StopModal.js';
import ProgressBar from './ProgressBar.js';
import Terminal from './Terminal.js';

import '../../css/App.css';

class StopButton extends Component {
    render() {
        return (
            <div className="submit-btn" onClick={() => this.props.stop()}>
                Stop Running
            </div>
        )
    }
}

class Running extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            outputFiles: "",
        };

        this.stop = this.stop.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showOutput = this.showOutput.bind(this);
    }

    stop() {
        this.setState({openModal: true});
    }

    handleClose() {
        this.setState({openModal: false});
    }

    showOutput(files) {
        this.setState({ outputFiles: files.url });
    }

    render() {
        let { projectName, ipAddress, stopRun, deleteInstance } = this.props;

        return (
            <div>
                <ProgressBar/>
                <Terminal
                    projectName={projectName}
                    ipAddress={ipAddress}
                    showOutput={this.showOutput}
                    deleteInstance={deleteInstance}/>

                <div className="upload-container">
                    <OutputFiles
                        outputFiles={this.state.outputFiles}/>
                    <StopButton
                        stop={this.stop}/>
                    <StopModal
                        openModal={this.state.openModal}
                        deletedTeam={this.state.deletedTeam}
                        stopRun={stopRun}
                        handleClose={this.handleClose}/>
                </div>
            </div>
        );
    }
}

export default Running;
